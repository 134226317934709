import React, { useState } from "react";
import { navigate } from "gatsby";
import { STATIC_FORM_KEY } from "../consts";
import { Button } from "../components/button";
import * as Alert from "../components/alert";
import SEO from "../components/seo";
import { Container } from "../components/container";
import { Nav } from "../components/nav";
import { Footer } from "../components/footer";
import { LabelInput } from "../components/label-input";
import { Table } from "../components/table";

type Product = {
  id: string;
  name: string;
  price: number;
  linkTo?: string;
};

const formatCurrency = (price: number) =>
  new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  }).format(price);

const englishProducts: Product[] = [
  {
    id: "A1",
    name:
      "Grammar Criteria System / Grammar Assessments / Grammar Hammer / IT Trackers",
    price: 750,
    linkTo: "/english/grammar/",
  },
  {
    id: "A2",
    name: "Spelling Progression System / Key Word System / Phonics System",
    price: 750,
    linkTo: "/english/spelling/",
  },
  {
    id: "A3",
    name:
      "Writing Criteria System / Composition Resources / Pupil Prompts / IT Tracker",
    price: 395,
    linkTo: "/english/writing/",
  },
];

const mathsProducts: Product[] = [
  {
    id: "B1",
    name: "Maths Criteria System / Maths Assessments / IT Tracker",
    price: 750,
    linkTo: "/maths/guidance/",
  },
  {
    id: "B2",
    name:
      "Numeracy Weekly Skills / IT Tracker / Pupil Prompts / Times Tables System / G&T Maths",
    price: 750,
    linkTo: "/maths/guidance/",
  },
];

const scienceProducts: Product[] = [
  {
    id: "C1",
    name: "Science Criteria System / Science Assessments / Science IT Tracker",
    price: 395,
    linkTo: "/science/assessment-and-tracking/",
  },
  {
    id: "C2",
    name: "KS2 Screen Criteria / KS2 Science Screens / Science PoS",
    price: 150,
    linkTo: "/science/screens-and-pos/",
  },
];

const behaviourProduct: Product[] = [
  {
    id: "D1",
    name: "Attitude, Behaviour System",
    price: 395,
    linkTo: "/attitude-behaviour-system",
  },
];

const specialOffers: Product[] = [
  {
    id: "SO1",
    name:
      "Reading Criteria System / Reading Assessments / Reading IT Tracker (ORT related)",
    price: 500,
  },
  {
    id: "SO2",
    name: "Guided Reading System (ORT related)",
    price: 500,
  },
  {
    id: "SO4",
    name: "Performance Management System",
    price: 350,
  },
  {
    id: "SO5",
    name: "Assertive Mentoring Implementation System",
    price: 350,
  },
];

const tables = [
  { name: "A. English Systems", items: englishProducts },
  { name: "B. Maths Systems", items: mathsProducts },
  { name: "C. Science Systems", items: scienceProducts },
  { name: "D. Attitude, Behaviour System", items: behaviourProduct },
];

const leadParagraphStyle = "mt-4 text-lg text-gray-500 leading-7 sm:mt-3";

const Form = () => {
  const [orderForm, setOrderForm] = useState({
    name: "",
    email: "",
    $phone: "",
    $positionRole: "",
    $school: "",
    $addressLine1: "",
    $addressLine2: "",
    $addressLine3: "",
    $postcode: "",
    subject: "Website enquiry",
    honeypot: "", // if any value received in this field, form submission will be ignored.
    products: [] as Product[],
    $products: "",
    message: "",
    replyTo: "@", // this will set replyTo of email to email address entered in the form
    accessKey: STATIC_FORM_KEY,
  });

  const [response, setResponse] = useState({
    type: "",
    message: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setOrderForm({ ...orderForm, [e.target.name]: e.target.value });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await fetch("https://api.staticforms.xyz/submit", {
        method: "POST",
        body: JSON.stringify({
          ...orderForm,
          $products: orderForm.products.reduce(
            (str, prod) => `${str} \n | ${prod.id} ${prod.name}`,
            ""
          ),
        }),
        headers: { "Content-Type": "application/json" },
      });

      const json = await res.json();

      if (json.success) {
        setResponse({
          type: "success",
          message: "Thank you for reaching out to me.",
        });
        navigate("/thank-you");
      } else {
        setResponse({
          type: "error",
          message: json.message,
        });
      }
      setIsLoading(false);
    } catch (e) {
      setResponse({
        type: "error",
        message: e.message,
      });
      setIsLoading(false);
    }
  };

  return (
    <div>
      {orderForm.products.length ? (
        <div className="fixed z-10 p-5 bg-white rounded shadow bottom-5 right-5 ">
          <p className="font-semibold text-indigo-600">Total:</p>
          <p className="font-bold">
            {formatCurrency(
              orderForm.products.reduce(
                (total, prod) =>
                  // The "SO" id is free so don't add to the total
                  prod.id.includes("SO") ? total : total + prod.price,
                0
              )
            )}
          </p>
        </div>
      ) : null}
      <div className="markdown">
        <h1>Order Form</h1>
        <p className={leadParagraphStyle}>
          Check the boxes of the products you would like to purchase and fill
          out the form with your details. Or you can{" "}
          <a href="/assets/Product_Order_Form_2020.pdf">
            download the order form
          </a>{" "}
          and follow the instructions.
        </p>
        <p className="mt-4 text-sm text-gray-500">ALL prices are ex VAT</p>

        {tables.map((table) => (
          <div className="my-10" key={table.name}>
            <Table
              headers={[table.name, "Cost", "Order"]}
              rows={table.items.map((product, index) => [
                {
                  text: `${index + 1}. ${product.name}`,
                  linkTo: product.linkTo,
                },
                {
                  text: formatCurrency(product.price),
                },
                {
                  text: "checkbox",
                  isCheckbox: true,
                  isChecked: orderForm.products.some(
                    (orderedProduct) => orderedProduct.id === product.id
                  ),
                  onChange: (isChecked) =>
                    setOrderForm({
                      ...orderForm,
                      products: isChecked
                        ? [...orderForm.products, product]
                        : orderForm.products.filter(
                            (orderedProduct) => orderedProduct.id !== product.id
                          ),
                    }),
                },
              ])}
            />
          </div>
        ))}

        <h2> Special Bulk Offers</h2>
        <p className={leadParagraphStyle}>
          Any orders over £1,000 can choose any 1 FREE product from the list
          below.
        </p>

        <p className={leadParagraphStyle}>
          Any orders over £2,000 can choose any 2 FREE products from the list
          below.
        </p>
        <p className={leadParagraphStyle}>
          Any orders of £3,000 or more can choose any 3 FREE products from the
          list below.
        </p>
        <div className="my-10">
          <Table
            headers={["Special Offers", "Usually", "Free"]}
            rows={specialOffers.map((product, index) => [
              {
                text: `${index + 1}. ${product.name}`,
              },
              {
                text: formatCurrency(product.price),
              },
              {
                text: "checkbox",
                isCheckbox: true,
                isChecked: orderForm.products.some(
                  (orderedProduct) => orderedProduct.id === product.id
                ),
                onChange: (isChecked) =>
                  setOrderForm({
                    ...orderForm,
                    products: isChecked
                      ? [...orderForm.products, product]
                      : orderForm.products.filter(
                          (orderedProduct) => orderedProduct.id !== product.id
                        ),
                  }),
              },
            ])}
          />
        </div>

        <form
          method="POST"
          action="https://api.staticforms.xyz/submit"
          onSubmit={handleSubmit}
          className="mt-9 grid grid-cols-1 row-gap-6 sm:grid-cols-2 sm:col-gap-8"
        >
          <LabelInput
            name="name"
            label="Name"
            onChange={handleChange}
            isRequired
          />
          <LabelInput
            name="email"
            label="Email"
            type="email"
            onChange={handleChange}
            isRequired
          />
          <LabelInput
            name="$positionRole"
            label="Position/Role"
            onChange={handleChange}
            isRequired
          />
          <LabelInput
            name="$school"
            label="Name of school/organisation"
            onChange={handleChange}
            isRequired
          />
          {/* Honeypot */}
          <div className="hidden">
            <LabelInput name="honeypot" label="Honey" onChange={handleChange} />
          </div>
          <div className="sm:col-span-2">
            <LabelInput
              name="$phone"
              label="Phone"
              onChange={handleChange}
              isRequired
            />
          </div>
          <div className="sm:col-span-2">
            <LabelInput
              name="$addressLine1"
              label="Invoice/delivery address line 1"
              onChange={handleChange}
              isRequired
            />
          </div>
          <div className="sm:col-span-2">
            <LabelInput
              name="$addressLine2"
              label="Invoice/delivery address line 2"
              onChange={handleChange}
            />
          </div>
          <div className="sm:col-span-2">
            <LabelInput
              name="$addressLine3"
              label="Invoice/delivery address line 3"
              onChange={handleChange}
            />
          </div>
          <div className="sm:col-span-2">
            <LabelInput
              name="$postcode"
              label="Postcode"
              onChange={handleChange}
              isRequired
            />
          </div>
          <div className="sm:col-span-2">
            <LabelInput
              name="message"
              label="Additional Notes"
              onChange={handleChange}
              isTextarea
            />
          </div>

          <div className="sm:col-span-2">
            <Alert.Error
              message={response.message}
              canShow={response.type === "error"}
            />
          </div>
          <div className="text-right sm:col-span-2">
            <Button isLoading={isLoading}>Submit</Button>
          </div>
        </form>
      </div>
    </div>
  );
};

function Order() {
  return (
    <>
      <SEO
        title="Order form"
        description="Order Assertive Mentoring products"
      />
      <Nav />
      <Container className="p-8 bg-white">
        <Form />
      </Container>
      <Footer />
    </>
  );
}

export default Order;
