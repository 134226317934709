import React from "react";
import { Link } from "gatsby";
import { Button } from "../components/button";

const CheckBox = ({
  onChange,
  isChecked = false,
}: {
  isChecked?: boolean;
  onChange: (isChecked: boolean) => void;
}) => {
  return (
    <div>
      <input
        type="checkbox"
        onChange={() => onChange(!isChecked)}
        checked={isChecked}
        className="w-4 h-4 text-indigo-600 form-checkbox transition duration-150 ease-in-out"
      />
    </div>
  );
};

type Row = {
  text: string;
  isCheckbox?: boolean;
  onChange?: (isChecked: boolean) => void;
  isChecked?: boolean;
  linkTo?: string;
};

export const Table = ({
  headers,
  rows,
}: {
  headers: string[];
  rows: Array<Array<Row>>;
}) => {
  return (
    <div className="flex flex-col">
      <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
          <table className="min-w-full">
            <thead>
              <tr className="bg-gray-50">
                {headers.map((header) => (
                  <th
                    key={header}
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase border-b border-gray-200 leading-4"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white">
              {rows.map((row, i) => (
                <tr
                  key={i}
                  className={`${(i + 1) % 2 === 0 ? "bg-gray-50" : ""}`}
                >
                  {row.map((tableItem) => (
                    <td
                      key={tableItem.text}
                      className="md:max-w-lg px-6 py-4 text-sm font-medium text-gray-900 whitespace-no-wrap border-b border-gray-200 leading-5"
                    >
                      {tableItem.isCheckbox && tableItem.onChange ? (
                        <CheckBox
                          isChecked={tableItem.isChecked}
                          onChange={tableItem.onChange}
                        />
                      ) : (
                        <div>
                          <span className="mr-3">{tableItem.text}</span>
                          {tableItem.linkTo ? (
                            <Link to={tableItem.linkTo}>
                              <Button isOutline size="xsmall">
                                Info
                              </Button>
                            </Link>
                          ) : null}{" "}
                        </div>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
