import React from "react";

export const LabelInput = ({
  label,
  onChange,
  isRequired,
  name,
  isTextarea,
  type = "text",
}: {
  label: string;
  type?: string;
  name: string; // This is needed for static forms
  isRequired?: boolean;
  isTextarea?: boolean;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}) => {
  return (
    <div>
      <div className="flex justify-between">
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700 leading-5"
        >
          {label}
        </label>
        {!isRequired ? (
          <span className="text-sm text-gray-500 leading-5">Optional</span>
        ) : null}
      </div>
      <div className="mt-1 rounded-md shadow-sm">
        {isTextarea ? (
          <textarea
            name={name}
            className="block w-full form-textarea transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            onChange={onChange}
            required={isRequired}
          ></textarea>
        ) : (
          <input
            className="block w-full form-input transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            name={name}
            type={type}
            onChange={onChange}
            required={isRequired}
          />
        )}
      </div>
    </div>
  );
};
